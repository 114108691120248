import React, { useState, useEffect, useContext } from 'react';
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableFooter,
    Paper,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box
} from '@mui/material';
import { StyledTableRow, StyledTableCell } from './styledTable';
import {
    Delete as DeleteIcon
} from '@mui/icons-material';
import {
    getFirestore, collection, query, where, orderBy, getDocs, doc, deleteDoc, updateDoc,
    arrayUnion, arrayRemove, addDoc, serverTimestamp, limit, startAfter, startAt, endBefore, endAt
} from 'firebase/firestore';
import { FirebaseError } from 'firebase/app';
import { useNavigate } from 'react-router-dom';
import app from '../firebase-app';
import { AppContext } from '../AppContext';
import  ReadMore from './ReadMore';
import { checkSubscriptionPlan } from '../utils/billing';


const db = getFirestore(app);

const options = [
    { value: 'name', label: 'Name' },
    { value: 'createdOn', label: 'Created On' },
];

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalProjects, setTotalProjects] = useState(0);
    const [sortOption, setSortOption] = useState(options[1]);
    const [createProjectOpen, setCreateProjectOpen] = useState(false);
    const [newProjectName, setNewProjectName] = useState('');
    const [newProjectDescription, setNewProjectDescription] = useState('');
    // const [anchorEl, setAnchorEl] = useState(null);
    const [currentProject, setCurrentProject] = useState(null);
    const [teamMembers, setTeamMembers] = useState([]);
    const [manageTeamOpen, setManageTeamOpen] = useState(false);
    const [addMemberEmail, setAddMemberEmail] = useState('');
    const [addMemberRole, setAddMemberRole] = useState('');
    const { loading, setLoading } = useContext(AppContext)

    const navigate = useNavigate();

    const userId = localStorage.getItem('userId');

    useEffect(() => {
        setLoading(true);
        const projectsCollection = collection(db, 'projects');

        const totalProjectsQuery = query(projectsCollection, where('owner', '==', userId));
        getDocs(totalProjectsQuery)
            .then((querySnapshot) => {
                setTotalProjects(querySnapshot.size);
            })
            .catch((error) => {
                if (error instanceof FirebaseError) {
                    if (error.message === "Missing or insufficient permissions.") {
                        setLoading(false);
                        navigate(`/account`);
                        return;
                    } else {
                        // Handle other Firebase errors here
                    }
                } else {
                    // Handle general error
                    // console.log('Error getting projects:', error);
                }
                // console.log('Error getting projects:', error);
            });

        let order = "asc";
        if (sortOption.value === "createdOn") {
            order = "desc";
        }

        const projectsQuery = query(projectsCollection,
            where('owner', '==', userId),
            orderBy(sortOption.value, order),
            limit(rowsPerPage * (page + 1))
        );

        getDocs(projectsQuery)
            .then((querySnapshot) => {
                const projects = querySnapshot.docs.map((doc) => {
                    return { ...doc.data(), id: doc.id };
                });
                setProjects(projects.slice(rowsPerPage * page, rowsPerPage * (page + 1)));
                setLoading(false)
            }
            );

    }, [sortOption, page, rowsPerPage]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        // console.log(event.target.value);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSortOptionChange = (event) => {
        setSortOption(options.find((option) => option.value === event.target.value));
    };

    const handleCreateProjectClick = () => {
        if(projects.length > 1){
            checkSubscriptionPlan(userId)
            .then((plan) => {})
        }
        setCreateProjectOpen(true);
    };

    const handleCreateProjectClose = () => {
        setCreateProjectOpen(false);
        setNewProjectName('');
        setNewProjectDescription('');
    };

    const handleDeleteProjectConfirm = (event, currentProject) => {
        // console.log('delete project');
        // console.log(currentProject);
        if (currentProject) {
            const projectDocRef = doc(db, 'projects', currentProject.id);
            deleteDoc(projectDocRef)
                .then(() => {
                    setSortOption(sortOption === options[0] ? options[1] : options[0]);
                    setSortOption(sortOption === options[0] ? options[1] : options[0]);  // to refresh the projects list
                    setCurrentProject(null);
                })
                .catch((error) => {
                    // console.log('Error deleting project:', error);
                });
        }
    };

    const handleAddMemberClick = () => {
        if (currentProject && addMemberEmail && addMemberRole) {
            const usersCollectionRef = collection(db, 'users');
            const addquery = query(usersCollectionRef, where('email', '==', addMemberEmail));
            getDocs(addquery)
                .then((querySnapshot) => {
                    if (!querySnapshot.empty) {
                        const user = querySnapshot.docs[0].data();
                        const teamMember = { userId: user.uid, email: user.email, role: addMemberRole };
                        const projectDocRef = doc(db, 'projects', currentProject.id);
                        updateDoc(projectDocRef, {
                            teamMembers: arrayUnion(teamMember),
                        })
                            .then(() => {
                                setTeamMembers((prevTeamMembers) => [...prevTeamMembers, teamMember]);
                                setAddMemberEmail('');
                                setAddMemberRole('');
                            })
                            .catch((error) => {
                                // console.log('Error adding team member:', error);
                            });
                    }
                    else {
                        const teamMember = { email: addMemberEmail, role: addMemberRole };
                        const projectDocRef = doc(db, 'projects', currentProject.id);
                        updateDoc(projectDocRef, {
                            teamMembers: arrayUnion(teamMember),
                        })
                            .then(() => {
                                setTeamMembers((prevTeamMembers) => [...prevTeamMembers, teamMember]);
                                setAddMemberEmail('');
                                setAddMemberRole('');
                            })
                            .catch((error) => {
                                // console.log('Error adding team member:', error);
                            });
                    }
                })
                .catch((error) => {
                    // console.log('Error finding user:', error);
                });
        }
    };

    const handleDeleteTeamMemberClick = (teamMember) => {
        if (currentProject) {
            const projectDocRef = doc(db, 'projects', currentProject.id);
            updateDoc(projectDocRef, {
                teamMembers: arrayRemove(teamMember),
            })
                .then(() => {
                    setTeamMembers((prevTeamMembers) =>
                        prevTeamMembers.filter((member) => member.userId !== teamMember.userId)
                    );
                })
                .catch((error) => {
                    // console.log('Error deleting team member:', error);
                });
        }
    };

    const handleCreateProjectConfirm = () => {
        if (userId) {
            const projectsCollectionRef = collection(db, 'projects');
            addDoc(projectsCollectionRef, {
                name: newProjectName,
                description: newProjectDescription,
                owner: userId,
                teamMembers: [],
                createdOn: serverTimestamp(), // Add the createdOn field with server time
            })
                .then((docRef) => {
                    setSortOption(sortOption === options[0] ? options[1] : options[0]);
                    setSortOption(sortOption === options[0] ? options[1] : options[0]);  // to refresh the projects list
                    handleCreateProjectClose();
                })
                .catch((error) => {
                    // console.log('Error creating project:', error);
                });
        }
    };


    const handleManageTeamClick = (event, project) => {
        setCurrentProject(project);
        setManageTeamOpen(true);
        setTeamMembers(project.teamMembers);
    };

    const handleManageTeamClose = (event) => {
        setCurrentProject(null);
        setTeamMembers([]);
        setAddMemberEmail('');
        setAddMemberRole('');
        setManageTeamOpen(false);
    };

    const handleAddMemberEmailChange = (event) => {
        setAddMemberEmail(event.target.value);
    };

    const handleAddMemberRoleChange = (event) => {
        setAddMemberRole(event.target.value);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleProjectDescriptionChange = (event) => {
        setNewProjectDescription(event.target.value);
    };

    const handleNewProjectNameChange = (event) => {
        setNewProjectName(event.target.value);
    };

    const handleClick = (event, project) => {
        navigate(`/project?id=${project.id}&name=${project.name}`);
    };

    return (
        <Box p={4}>
            <ReadMore link={"https://www.easyllm.tech/docs/project-management.html"} urlName={"Project Management"} styleCode={'form-help-app'} />
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <FormControl>
                    <InputLabel id="sort-option-label">Sort By</InputLabel>
                    <Select
                        labelId="sort-option-label"
                        value={sortOption.value}
                        onChange={handleSortOptionChange}
                    >
                        <MenuItem value="createdOn">Created On</MenuItem>
                        <MenuItem value="name">Name</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="contained" color="primary" onClick={handleCreateProjectClick}>
                    Create Project
                </Button>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                            <StyledTableCell>Created On</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            {/* <StyledTableCell>Manage Team</StyledTableCell> */}
                            <StyledTableCell>Delete Project</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {projects.map((project) => (

                            <StyledTableRow key={project.id}>
                                <StyledTableCell>{project.name}</StyledTableCell>
                                <StyledTableCell>{project.description}</StyledTableCell>
                                <StyledTableCell>{project.createdOn.toDate().toLocaleString()}</StyledTableCell>
                                <StyledTableCell>
                                    <Button
                                        aria-label="open"
                                        aria-controls="open"
                                        aria-haspopup="true"
                                        color="primary"
                                        variant="contained"
                                        onClick={(event) => handleClick(event, project)}
                                    >
                                        View Project
                                    </Button>
                                </StyledTableCell>
                                {/* <StyledTableCell>
                                    <IconButton
                                        aria-label="team"
                                        aria-controls="team"
                                        aria-haspopup="true"
                                        onClick={(event) => handleManageTeamClick(event, project)}
                                    >
                                        <GroupsIcon />
                                    </IconButton>
                                </StyledTableCell> */}
                                <StyledTableCell>
                                    <IconButton
                                        aria-label="delete"
                                        aria-controls="delete"
                                        aria-haspopup="true"
                                        onClick={(event) => handleDeleteProjectConfirm(event, project)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </StyledTableCell>
                            </StyledTableRow>

                        ))}
                        {projects.length === 0 && (
                            <StyledTableRow>
                                <StyledTableCell colSpan={6} align="center">
                                    You have not created any projects yet. Click the "Create Project" button
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <StyledTableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                count={totalProjects}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </StyledTableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Dialog open={createProjectOpen} onClose={handleCreateProjectClose}>
                <DialogTitle>Create New Project</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        type="text"
                        fullWidth
                        value={newProjectName}
                        onChange={handleNewProjectNameChange}
                    />
                    <TextField
                        margin="dense"
                        label="Description"
                        type="text"
                        fullWidth
                        value={newProjectDescription}
                        onChange={handleProjectDescriptionChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateProjectClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCreateProjectConfirm} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <Dialog open={manageTeamOpen} onClose={(event) => handleManageTeamClose(event)}>
                <DialogTitle>Manage Team</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Email</StyledTableCell>
                                    <StyledTableCell>Role</StyledTableCell>
                                    <StyledTableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {teamMembers.map((teamMember) => (
                                    <TableRow key={teamMember.userId}>
                                        <StyledTableCell>{teamMember.email}</StyledTableCell>
                                        <StyledTableCell>{teamMember.role}</StyledTableCell>
                                        <StyledTableCell>
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => handleDeleteTeamMemberClick(teamMember)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={3}>
                        <TextField
                            label="Add Member"
                            value={addMemberEmail}
                            onChange={handleAddMemberEmailChange}
                            fullWidth
                        />
                        <FormControl fullWidth>
                            <InputLabel>Role</InputLabel>
                            <Select value={addMemberRole} onChange={handleAddMemberRoleChange}>
                                <MenuItem value="viewer">Viewer</MenuItem>
                                <MenuItem value="editor">Editor</MenuItem>
                                <MenuItem value="owner">Owner</MenuItem>
                            </Select>
                        </FormControl>
                        <Box mt={3} display="flex" justifyContent="flex-end">
                            <Button variant="contained" color="primary" onClick={handleAddMemberClick}>
                                Invite
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(event) =>handleManageTeamClose(event)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog> */}
        </Box>
    );
}

export default Projects;