import React,{useEffect, useState} from 'react';
import AppLayout from '../components/AppLayout';
import Projects from '../components/ProjectManagement';
import {checkSubscriptionPlan} from "../utils/billing";

const App = () => {

  const user = localStorage.getItem('userId');
  const [userPlan, setUserPlan] = useState('starter');
  useEffect(() => {
    checkSubscriptionPlan(user, false)
      .then((plan) => {
        setUserPlan(plan);
      });
  }, [user]);

  return (
    <AppLayout>
      <Projects />
    </AppLayout>
  );
};

export default App;

