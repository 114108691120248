import React, { useState, useEffect, useContext} from 'react';
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    Paper,
    IconButton,
    Button,
    MenuItem,
    Menu,
    Box,
    Typography,
    Chip,
    TableFooter,
    Dialog, List, ListItem, ListItemText, Breadcrumbs, Link
} from '@mui/material';
import { StyledTableRow, StyledTableCell } from './styledTable';
import {
    MoreVert as MoreVertIcon,
    Add as AddIcon
} from '@mui/icons-material';
import { getFirestore, collection, query, orderBy, getDocs, getDoc, doc, deleteDoc, updateDoc, limit, startAfter } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { apiUrl } from "../secrets";
import {getBestFinetune, getMetricInfo, isStringInLatestVersionStatus, checkWandbCookie} from "../utils/versionManagement";
import getTokens from '../utils/auth';
import app from '../firebase-app';
import { verifyService } from '../utils/serviceManagement';
import { getUserSettings } from '../utils/userManagement';
import { AppContext } from '../AppContext';
import ReadMore from './ReadMore';
import CloseIcon from '@mui/icons-material/Close';
import {checkSubscriptionPlan} from "../utils/billing";

const db = getFirestore(app);

const url = apiUrl + '/' + localStorage.getItem('userId');

const columns = [
    { id: 'versionNumber', label: 'Version Number', minWidth: 170 },
    { id: 'createdDate', label: 'Created Date', minWidth: 100 },
    // { id: 'createdBy', label: 'Created By', minWidth: 170 },
    { id: 'production', label: 'Production', minWidth: 100 },
    { id: 'actions', label: 'Actions', minWidth: 100 },
];

const Versions = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [selectedRow, setSelected] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showStatusOpen, setShowStatusOpen] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const navigate = useNavigate();
    const {loading, setLoading} = useContext(AppContext)

    //get project id from query string
    const urlParams = new URLSearchParams(window.location.search);
    const projectIdFromUrl = urlParams.get('id');
    const projectNameFromUrl = urlParams.get('name');

    const projectId = projectIdFromUrl;

    // console.log('projectIdFromUrl', projectIdFromUrl);

    const currentUserId = localStorage.getItem('userId');

    const verifyProjectAndFetchVersions = async () => {
        // Verify project is in projects list
        let canContinue= verifyService(navigate);
        if(!canContinue){
            return false;
        }

        const headers = await getTokens();
        const projectsResponse = await fetch(`${url}/getProjects`, {headers: headers});
        const projectsData = await projectsResponse.json();
        const projectExists = projectsData.projects.includes(projectId);
        if (!projectExists) {
            return false;
        }

        // Fetch project versions
        const versionsResponse = await fetch(`${url}/getVersions/${projectId}`, {headers: headers});
        const versionsData = await versionsResponse.json();
        const latestVersion = versionsData.latest_version;
        const productionVersion = versionsData.production_version;

        // Compare with Firebase data
        const versionsCollectionRef = collection(db, 'projects', projectId, 'versions');
        const versionsSnapshot = await getDocs(versionsCollectionRef);
        const latestVersionFromFirebase = versionsSnapshot.docs.reduce((acc, doc) => {
            const versionNumber = doc.data().versionNumber;
            return versionNumber > acc ? versionNumber : acc;
        }, -Infinity);

        const productionVersionFromFirebase = versionsSnapshot.docs.reduce((acc, doc) => {
            const versionNumber = doc.data().versionNumber;
            const isProduction = doc.data().production;
            return isProduction && versionNumber > acc ? versionNumber : acc;
        }, -Infinity);

        if (latestVersion !== latestVersionFromFirebase || productionVersion !== productionVersionFromFirebase) {
            return false;
        }
        else {
            return true;
        }
    };

    const fetchVersions = async () => {
        const q = query(
            collection(db, 'projects', projectIdFromUrl, 'versions'),
            orderBy('versionNumber', 'asc'),
            limit(rowsPerPage * (page + 1))
        );

        const querySnapshot = await getDocs(q);
        const rows = [];
        querySnapshot.forEach((doc) => {
            rows.push({
                id: doc.id,
                versionNumber: doc.data().versionNumber,
                createdDate: doc.data().createdDate,
                // createdBy: doc.data().createdBy,
                production: doc.data().production,
            });
        });
        setRows(rows.slice(page * rowsPerPage, rowsPerPage * (page + 1)));
        setLoading(false)
    };

    useEffect(() => {
        setLoading(true)
        fetchVersions();
    }, [page, rowsPerPage]);

    useEffect(() => {
        const fetchTotalRows = async () => {
            const q = query(
                collection(db, 'projects', projectIdFromUrl, 'versions'),
                orderBy('versionNumber', 'asc')
            );

            const querySnapshot = await getDocs(q);
            setTotalRows(querySnapshot.size);
        };

        fetchTotalRows();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getStauslist = async (versionId) => {
        const versionRef = doc(db, 'projects', projectId, 'versions', versionId);

        // Get the current status array
        const versionDoc = await getDoc(versionRef);
        const currentStatus = versionDoc.data().status || [];

        return currentStatus;
    };

    const handleShowStatusOpen = (versionId) => {
        setLoading(true);
        getStauslist(versionId).then((statusList) => {
            if (statusList) {
                if (statusList.length > 0) {
                    setStatusList(statusList);
                }
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false)
        })
        setShowStatusOpen(true);
    };

    const handleShowStatusClose = () => {
        setShowStatusOpen(false);
    };

    const handleDelete = async (id, deleteDataset = true) => {
        setLoading(true);
        try {
            const wandbCookie = await getUserSettings(currentUserId).then((settings) => {
                if (settings) {
                    const wandbCookie = settings.wandbCookie || null;
                    if (wandbCookie) {
                        return wandbCookie;
                    }
                    else {
                        alert("Please add Weights & Biases browser cookie");
                        navigate(`/userSettings`);
                        return null;
                    }
                }
                else {
                    alert("Please add Weights & Biases browser cookie");
                    navigate(`/userSettings`);
                    return null;
                }
            });

            const wandbCookieValidated = await checkWandbCookie(navigate, wandbCookie);

            if(!wandbCookieValidated){
                alert("Your Weights & Biases browser cookie expired or invalid. Please provide new W&B browser cookie");
                navigate(`/userSettings`);
            }

            if (wandbCookieValidated) {
                const versionRef = doc(db, 'projects', projectId, 'versions', id);
                const currentVersionSnapshot = await getDoc(versionRef);
                const currentVersionNumber = currentVersionSnapshot.data().versionNumber;

                const canContinue = await verifyService(navigate);
                if(!canContinue){
                    return false;
                }

                const headers = await getTokens();
                // Call the API to delete the version
                const response = await fetch(`${url}/deleteVersion`, {
                    method: 'POST',
                    headers: {...{
                        'Content-Type': 'application/json'
                    }, ...headers},
                    body: JSON.stringify({
                        project: projectNameFromUrl,
                        version: currentVersionNumber,
                        cookie: wandbCookie,
                        delete_dataset: deleteDataset
                    })
                });

                // Check the response for success
                const data = await response.json();
                if (data.status === 'Success') {

                    if(deleteDataset){
                    // Delete the document with the specified ID
                    // console.log('Deleting version', id)
                    await deleteDoc(doc(db, 'projects', projectId, 'versions', id));

                    await fetchVersions();
                    }
                    else{
                        const versionRef = doc(db, 'projects', projectId, 'versions', id);

                        //update the status array
                        const updatedStatus = ['dataset creation started', 'dataset created'];

                        // Update the status field in the version document
                        await updateDoc(versionRef, { status: updatedStatus });
                    }
                } else {
                    // console.log('API error:', data);
                    if(deleteDataset){
                        alert('Error deleting full version. Please contact support');
                    }
                    else{
                        alert('Error deleting finetunes. Please contact support');
                    }
                }
            }

        } catch (error) {
            // console.log('Error:', error);
            if(deleteDataset){
                alert('Error deleting full version. Please contact support');
            }
            else{
                alert('Error deleting finetunes. Please contact support');
            }
        }
        setLoading(false);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClickMenu = (event, row) => {
        setSelected(row);
        setAnchorEl(event.currentTarget);
    };

    const handleRevertToThisVersion = async (id) => {
        const canContinue = await verifyService(navigate);
        if(!canContinue){
            return false;
        }

        const wandbCookie = await getUserSettings(currentUserId).then((settings) => {
            if (settings) {
                const wandbCookie = settings.wandbCookie || null;
                if (wandbCookie) {
                    return wandbCookie;
                }
                else {
                    alert("Please add Weights & Biases browser cookie");
                    navigate(`/userSettings`);
                    return null;
                }
            }
            else {
                alert("Please add Weights & Biases browser cookie");
                navigate(`/userSettings`);
                return null;
            }
        });

        const wandbCookieValidated = await checkWandbCookie(navigate, wandbCookie);

        if(!wandbCookieValidated){
            alert("Your Weights & Biases browser cookie expired or invalid. Please provide new W&B browser cookie");
            navigate(`/userSettings`);
        }

        const versionRef = doc(db, 'projects', projectId, 'versions', id);
        const currentVersionSnapshot = await getDoc(versionRef);
        const currentVersionNumber = currentVersionSnapshot.data().versionNumber;

        // Set the specified version as production
        await updateDoc(versionRef, { production: true });

        // Delete all versions with versionNumber greater than currentVersionNumber
        var versionsCollectionRef = collection(db, 'projects', projectId, 'versions');
        var versionsSnapshot = await getDocs(versionsCollectionRef);
        const versionsToDelete = versionsSnapshot.docs.filter(doc => {
            const versionNumber = doc.data().versionNumber;
            return versionNumber > currentVersionNumber;
        });

        await Promise.all(versionsToDelete.map(doc => deleteDoc(doc.ref)));

        // Update all other versions as non-production
        versionsCollectionRef = collection(db, 'projects', projectId, 'versions');
        versionsSnapshot = await getDocs(versionsCollectionRef);
        const versionsToUpdate = versionsSnapshot.docs.filter(doc => {
            const versionNumber = doc.data().versionNumber;
            return versionNumber !== currentVersionNumber;
        });

        await Promise.all(versionsToUpdate.map(doc => updateDoc(doc.ref, { production: false })));

        const { metricName, metricGoal } = await getMetricInfo(projectId, id);

        try {
            // Call the API to rollback the version
            const rollbackUrl = `${url}/rollbackVersion`;
            const data = {
                project: projectNameFromUrl,
                version: currentVersionNumber,
                cookie: wandbCookie,
                type: 'revert',
                goal: metricGoal,
                metric: metricName
            };

            const headers = await getTokens();
            const response = await fetch(rollbackUrl, {
                method: 'POST',
                headers: {...{
                    'Content-Type': 'application/json'
                }, ...headers},
                body: JSON.stringify(data)
            });
            const result = await response.json();
            if (result.status === 'Success') {
                // Update the rows state with the new data
                await fetchVersions();
            } else {
                // console.log('Revert failed');
                alert('Revert failed in Weights and Biases. Please contact support');
            }
        } catch (error) {
            // console.log('Error:', error);
            alert('Revert failed in Weights and Biases. Please contact support');

        }
    };

    const handleMarkAsProduction = async (id) => {
        const canContinue = await verifyService(navigate);
        if(!canContinue){
            return false;
        }

        const wandbCookie = await getUserSettings(currentUserId).then((settings) => {
            if (settings) {
                const wandbCookie = settings.wandbCookie || null;
                if (wandbCookie) {
                    return wandbCookie;
                }
                else {
                    alert("Please add Weights & Biases browser cookie");
                    navigate(`/userSettings`);
                    return null;
                }
            }
            else {
                alert("Please add Weights & Biases browser cookie");
                navigate(`/userSettings`);
                return null;
            }
        });

        const wandbCookieValidated = await checkWandbCookie(navigate, wandbCookie);

        if(!wandbCookieValidated){
            alert("Your Weights & Biases browser cookie expired or invalid. Please provide new W&B browser cookie");
            navigate(`/userSettings`);
        }

        const versionRef = doc(db, 'projects', projectId, 'versions', id);
        const currentVersionSnapshot = await getDoc(versionRef);
        const currentVersionNumber = currentVersionSnapshot.data().versionNumber;

        // Set the specified version as production
        await updateDoc(versionRef, { production: true });

        // Update all other versions as non-production
        const versionsCollectionRef = collection(db, 'projects', projectId, 'versions');
        const versionsSnapshot = await getDocs(versionsCollectionRef);
        const versionsToUpdate = versionsSnapshot.docs.filter(doc => {
            const versionNumber = doc.data().versionNumber;
            return versionNumber !== currentVersionNumber;
        });

        await Promise.all(versionsToUpdate.map(doc => updateDoc(doc.ref, { production: false })));

        const { metricName, metricGoal } = await getMetricInfo(projectId, id);

        try{
        // Call the API to mark this version as production
        const data = {
            project: projectNameFromUrl,
            version: currentVersionNumber,
            cookie: wandbCookie,
            type: 'normal',
            goal: metricGoal,
            metric: metricName
        };

        const headers = await getTokens();
        const response = await fetch(`${url}/rollbackVersion`, {
            method: 'POST',
            headers: {...{
                'Content-Type': 'application/json'
            }, ...headers},
            body: JSON.stringify(data)
        });
        const result = await response.json();
        if (result.status === 'Success') {
            // Update the rows state with the new data
            await fetchVersions();
        } else {
            // console.log('Mark As Production  failed');
            alert('Mark As Production failed in Weights and Biases. Please contact support');
        }
        } catch (error) {	
            // console.log('Error:', error);	
            alert('Mark As Production failed in Weights and Biases in Weights and Biases. Please contact support');
        }
        };

    const handleCreate = () => {
        if(rows.length > 1){
            checkSubscriptionPlan(currentUserId)
            .then((plan) => {})
        }
    
        isStringInLatestVersionStatus(projectIdFromUrl,"Completed process").then((latestVersionCompleted)=>{
            if (latestVersionCompleted) {
                navigate(`/createFinetune?projectId=${projectIdFromUrl}&version=${rows.length + 1}&name=${projectNameFromUrl}&step=0`);
            }
            else {
                alert("Please complete the progress on previous version or delete the incomplete version before creating new version")
            }
        }); 
    };

    const handleFinetuneAgain = () => {
        isStringInLatestVersionStatus(projectIdFromUrl,"Completed process").then((latestVersionCompleted)=>{
            if (latestVersionCompleted) {
                navigate(`/createFinetune?projectId=${projectIdFromUrl}&version=${rows.length}&name=${projectNameFromUrl}&step=1`);
            }
            else {
                alert("You need to complete the progress on current version by clicking \"Resume on Finetune Wizard\" before doing finetuning again. Or delete the current version and retry from beginning")
            }
        });
    };

    const handleResumeWizard = () => {
        isStringInLatestVersionStatus(projectIdFromUrl,"dataset created").then((datasetCreated)=>{
            if (datasetCreated) {
                navigate(`/createFinetune?projectId=${projectIdFromUrl}&version=${rows.length}&name=${projectNameFromUrl}&step=1`);
            }
            else {
                isStringInLatestVersionStatus(projectIdFromUrl,"failed to create dataset").then((datasetCreationFailed)=>{
                    if (datasetCreationFailed) {
                        alert("dataset creation failed for this version. delete the current version and retry from beginning. Contact support if needed")
                    }
                    else {
                        navigate(`/createFinetune?projectId=${projectIdFromUrl}&version=${rows.length}&name=${projectNameFromUrl}&step=1`);
                    }
                });
            }
        });
    }

    const handleShowBestFinetune = async (versionId, version) => {
        try {
            setLoading(true);
            const latestVersionCompleted = await isStringInLatestVersionStatus(projectIdFromUrl,"Completed process");
    
            if (latestVersionCompleted) {
                try {
                    const bestModel = await getBestFinetune(navigate, projectIdFromUrl, versionId, projectNameFromUrl, version);
                    
                    if(bestModel){
                        alert(`${bestModel} is the best finetuned model`);
                    }
                    else{
                        alert(`Not able to get the best finetuned model. Click show progress to check whether finetunes are created`);
                    }
                } catch(error) {
                    alert(`Not able to get the best finetuned model. Click show progress to check whether finetunes are created`);
                }
            }
            else {
                alert("You need to complete the progress on current version by clicking \"Resume on Finetune Wizard\" before clicking 'Show Best Finetune'. Or delete the current version and retry from beginning")
            }
        } catch(error) {
            alert(`Not able to get the best finetuned model. Click show progress to check whether finetunes are created`);
        } finally {
            setLoading(false);
        }
    };    

    return (
        <Box sx={{ p: 3 }}>
            <Breadcrumbs aria-label="breadcrumb" mb={3}>
                    <Link underline="hover" href="/">
                        Projects
                    </Link>
                    <Typography>
                        {projectNameFromUrl}
                    </Typography>
                </Breadcrumbs>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h5">Versions</Typography>
                <Button variant="contained" onClick={handleCreate} startIcon={<AddIcon />}>
                    Create
                </Button>
            </Box>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-label="Versions table">
                        <TableHead>
                            <StyledTableRow>
                                {columns.map((column) => (
                                    <StyledTableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => {
                                return (
                                    <StyledTableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <StyledTableCell component="th" scope="row">
                                            {row.versionNumber}
                                        </StyledTableCell>
                                        <StyledTableCell>{row.createdDate.toDate().toLocaleDateString()}</StyledTableCell>
                                        {/* <StyledTableCell>{row.createdBy}</StyledTableCell> */}
                                        <StyledTableCell>
                                            {row.production && <Chip label="Production" />}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <IconButton onClick={(event) => handleClickMenu(event, row)}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl)}
                                                onClose={handleMenuClose}
                                            >

                                                <MenuItem onClick={() => handleShowStatusOpen(selectedRow.id)}>Show Progress</MenuItem>
                                                <MenuItem onClick={() => handleShowBestFinetune(selectedRow.id,selectedRow.versionNumber)}>Show Best Finetune</MenuItem>
                                                {selectedRow.id === rows[rows.length - 1].id && (
                                                    <MenuItem onClick={() => handleResumeWizard(selectedRow.id)}>Resume on finetune wizard</MenuItem>
                                                )}
                                                {selectedRow.production === false && (
                                                    <MenuItem onClick={() => handleMarkAsProduction(selectedRow.id)} value="markAsProduction">Mark as Production</MenuItem>
                                                )}
                                                {(selectedRow.id !== rows[rows.length - 1].id && rows.length > 1) && (
                                                    <MenuItem onClick={() => handleRevertToThisVersion(selectedRow.id)}>Revert To this Version</MenuItem>
                                                )}
                                                {selectedRow.id === rows[rows.length - 1].id && (
                                                    <MenuItem onClick={() => handleFinetuneAgain(selectedRow.id)}>Finetune again</MenuItem>
                                                )}
                                                {selectedRow.id === rows[rows.length - 1].id && (
                                                    <MenuItem onClick={() => handleDelete(selectedRow.id, false)}>Delete Finetunes</MenuItem>
                                                )}
                                                {selectedRow.id === rows[rows.length - 1].id && (
                                                    <MenuItem onClick={() => handleDelete(selectedRow.id)}>Delete Full Version</MenuItem>
                                                )}
                                            </Menu>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                            {rows.length === 0 && (
                                <StyledTableRow>
                                    <StyledTableCell colSpan={6} align="center">
                                    You have not created any versions yet. Click the "Create Version" button
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20]}
                                    colSpan={6}
                                    count={totalRows}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <Dialog open={showStatusOpen} onClose={handleShowStatusClose}>
                    <div className='status-popup'>Progress<CloseIcon onClick={handleShowStatusClose} className='icon'/></div>
                    <List>
                        {statusList.map((item, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={item} />
                            </ListItem>
                        ))}
                    </List>
                </Dialog>
            </Paper>
            <ReadMore link={"https://www.easyllm.tech/docs/version-management.html"} urlName={"Version Management"} styleCode={'form-help-app'}/>
        </Box>
    );

};

export default Versions;
