import React, { createContext, useState, useEffect } from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        let timer1, timer2;
        if (loading) {
            timer1 = setTimeout(() => {
                setMessage('Please wait ...');
            }, 5000); // 10 seconds

            timer2 = setTimeout(() => {
                setMessage('We are setting up your secure environment. Please wait for sometime. It may take some minutes to finish');
            }, 15000); // 30 seconds
        } else {
            setMessage('');
        }

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    }, [loading]);

    return (
        <AppContext.Provider value={{ loading, setLoading }}>
            <Box position="relative">
                {children}
                {loading && (
                    <Box
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        width="100%"
                        height="100%"
                        display="flex"
                        flexDirection={'column'}
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="rgba(0, 0, 0, 0.5)"
                        zIndex={9999}
                        position="fixed"
                    >
                        <CircularProgress color="success" style={{'color': '#10a37f'}} />
                        <Typography variant="h6" color="white" style={{ marginTop: '20px' }}>
                            {message}
                        </Typography>
                    </Box>
                )}
            </Box>
        </AppContext.Provider>
    );
};