import { getFirestore, doc, getDoc, updateDoc, collection, getDocs, query, limit, orderBy } from 'firebase/firestore';
import { apiUrl } from "../secrets";
import app from '../firebase-app';
import { verifyService } from './serviceManagement';
import getTokens from './auth';

const url = apiUrl + '/' + localStorage.getItem('userId');

const db = getFirestore(app);

const storeConfig = async (projectId, versionId, config, type) => {
  const versionRef = doc(db, 'projects', projectId, "versions", versionId);
  if(type==="finetune"){
  await updateDoc(versionRef, { "finetuneConfigState": config });}
  else if(type==="inference"){
    await updateDoc(versionRef, { "inferenceConfigState": config });
  }
  else if(type==="dataset"){
    await updateDoc(versionRef, { "datasetConfigState": config });
  }
};

const getMetricInfo = async (projectId, versionId) => {
  try {
    const versionRef = doc(db, 'projects', projectId, "versions", versionId);
    const versionDoc = await getDoc(versionRef);
    const inferenceConfigState = versionDoc.data().inferenceConfigState;
    const metricName = inferenceConfigState.metricName;
    const metricGoal = inferenceConfigState.metricGoal;
    // console.log(`Successfully retrieved metricName: ${metricName} and metricGoal: ${metricGoal}`);
    return { metricName, metricGoal };
  } catch (error) {
    // console.error(`Error retrieving metricName and metricGoal: ${error}`);
    return null;
  }
};

const fetchFinetuneConfig = async (projectId, versionId) => {
  const versionRef = doc(db, 'projects', projectId, 'versions', versionId);

  try {
    const versionDoc = await getDoc(versionRef);
    if (versionDoc.exists() && versionDoc.data().finetuneConfigState) {
      return versionDoc.data().finetuneConfigState;
    } else {
      return null;
    }
  } catch (error) {
    // console.error('Error fetching finetune config:', error);
    return null;
  }
};

/**
 * Get the versionId from the versions collection based on the versionNumber.
 *
 * @param {string} projectId - The project ID.
 * @param {number} versionNumber - The version number to search for.
 * @returns {Promise<string|null>} - The versionId if found, otherwise null.
 */
async function getVersionIdByNumber(projectId, versionNumber) {
  try {
    // Get the versions collection reference
    const versionsRef = collection(db, "projects", projectId, "versions");

    // Get the versions collection snapshot
    const versionsSnapshot = await getDocs(versionsRef);

    // Iterate through the versions collection to find the version with the specified versionNumber
    for (const docSnapshot of versionsSnapshot.docs) {
      const versionData = docSnapshot.data();
      if (versionData.versionNumber === versionNumber) {
        return docSnapshot.id;
      }
    }

    // If the versionNumber is not found, return null
    // console.error(`Version number ${versionNumber} not found.`);
    return null;
  } catch (error) {
    // console.error("Error getting versionId:", error);
    return null;
  }
}

const getInferenceConfigFromFirebase = async (projectId, versionId, version) => {
  try {
    const docRef = doc(db, "projects", projectId, "versions", versionId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().inferenceConfigState) {
      return docSnap.data().inferenceConfigState;
    } else if (version > 1) {
      const previousVersionId = await getVersionIdByNumber(projectId,version - 1);
      const previousDocRef = doc(db, "projects", projectId, "versions", previousVersionId);
      const previousDocSnap = await getDoc(previousDocRef);
      if (previousDocSnap.exists() && previousDocSnap.data().inferenceConfigState) {
        return previousDocSnap.data().inferenceConfigState;
      } else {
        // console.log("No previous version config document!");
        return null;
      }
    }
  } catch (error) {
    // console.error("Error getting config:", error);
  }
  
  return null;
};

const getCreateDatasetConfigFromFirebase = async (projectId, version) => {
  try {
    const versionId = await getVersionIdByNumber(projectId, version);
    const docRef = doc(db, "projects", projectId, "versions", versionId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().datasetConfigState) {
      return docSnap.data().datasetConfigState;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

/**
 * Get the version number by version ID.
 *
 * @param {string} projectId - The ID of the project document.
 * @param {string} versionId - The ID of the version document.
 * @returns {Promise<number>} - A promise that resolves to the version number.
 * @throws {Error} - If the version document is not found or there is an error fetching the data.
 */
async function getVersionById(projectId, versionId) {
  try {
    // Get the reference to the version document
    const versionDocRef = doc(db, "projects", projectId, "versions", versionId);

    // Fetch the version document
    const versionDocSnap = await getDoc(versionDocRef);

    // Check if the version document exists
    if (!versionDocSnap.exists()) {
      throw new Error("Version document not found");
    }

    // return the document data
    return versionDocSnap.data()
  } catch (error) {
    // Handle exceptions and re-throw the error
    // console.error("Error fetching version:", error);
    throw error;
  }
}

async function getBestFinetune(navigate, projectId, versionId, project, version) {
  try {
    const canContinue = await verifyService(navigate);
    if(!canContinue){
        return null;
    }
    // Fetch project versions
    const { metricName, metricGoal } = await getMetricInfo(projectId, versionId);

    // Fetch best finetune
    const headers = await getTokens(); 
    const response = await fetch(
      `${url}/getBestFinetune?project=${project}&version=${version}&goal=${metricGoal}&metric=${metricName}`, {
        headers: headers
      }
    );

    if (response.status !== 200) {
      // console.error(`Error fetching best finetune: ${response.statusText}`);
      throw new Error(response.statusText);
    }

    const data = await response.json();
    const bestFinetune = data.finetune;

    if (!bestFinetune) {
      // console.error("Error: Best finetune not found");
      // throw new Error("Best finetune not found");
      return null;
    }

    // console.log(`Best finetune for project ${project}, version ${version}: ${bestFinetune}`);
    return bestFinetune;
  } catch (error) {
    // console.error(`Error in getBestFinetune function: ${error.message}`);
    // throw error;
    return null;
  }
}

async function checkWandbCookie(navigate, cookie) {
  try {
    const canContinue = await verifyService(navigate);
    if(!canContinue){
        return null;
    }

    // Fetch best finetune
    const headers = await getTokens(); 
    const response = await fetch(
      `${url}/checkWandbCookie?cookie=${cookie}`, {
        headers: headers
      }
    );

    if (response.status !== 200) {
      // console.error(`Error fetching best finetune: ${response.statusText}`);
      throw new Error(response.statusText);
    }

    const data = await response.json();
    const valid = data.valid;

    if (!valid) {
      // console.error("Error: Best finetune not found");
      // throw new Error("Best finetune not found");
      return false;
    }

    return valid;
  } catch (error) {
    // console.error(`Error in getBestFinetune function: ${error.message}`);
    // throw error;
    return false;
  }
}

const isStringInLatestVersionStatus = async (projectId, searchString) => {
  
  const projectRef = doc(db, 'projects', projectId);
  const versionsRef = collection(projectRef, 'versions');

  // Get the latest version
  const q = query(versionsRef, orderBy('versionNumber', 'desc'), limit(1));
  const snapshot = await getDocs(q);

  if (snapshot.empty) {
      // console.log("empty versions")
      return true;
  }

  const latestVersion = snapshot.docs[0].data();

  // Check if the searchString is in the status field of the latest version
  if (Array.isArray(latestVersion.status) && latestVersion.status.includes(searchString)) {
      // console.log("Not empty versions")
      return true;
  }

  return false;
};

export {getVersionById, storeConfig, fetchFinetuneConfig, getVersionIdByNumber, 
  getInferenceConfigFromFirebase, getBestFinetune, getMetricInfo, isStringInLatestVersionStatus,
  getCreateDatasetConfigFromFirebase, checkWandbCookie};